<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="80%" no-header id="add_voucher" backdrop style="direction:ltr" right :title="pagename" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:`+$parent.lang.dir">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{pagename}}</span>
                    </div>
                    <div @click="hide" id="vohclose" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="2" sm="2">
                                <label for="input-live">{{$parent.lang.jvnom}}</label>
                                <b-form-input
                                    id="input-live"
                                    v-model="jvnom"
                                    aria-describedby="input-live-help input-live-feedback"
                                    prepend-icon="mdi-calendar"
                                    :placeholder="$parent.lang.jvnom"   
                                    class="input-sm inborder"
                                ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="4" sm="8">
                                <v-dialog
                                  ref="dialog"
                                  v-model="modal"
                                  :return-value.sync="date"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <label for="input-live">{{$parent.lang.date}}</label>
                                    <b-form-input
                                      id="input-live"
                                      v-model="jv_date"
                                      aria-describedby="input-live-help input-live-feedback"
                                      prepend-icon="mdi-calendar"
                                      :placeholder="$parent.lang.date"
                                      readonly
                                      trim
                                      v-bind="attrs"
                                      v-on="on"
                                      class="input-sm inborder"
                                    ></b-form-input>
                                  </template>
                                  <v-date-picker
                                    v-model="jv_date"
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="modal = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog.save(jv_date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" md="4" sm="2"></v-col>
                        </v-row>
                        <v-row>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-center" style="width:10%">{{$parent.lang.account}}</th>
                                        <th class="text-center" style="width:40%">{{$parent.lang.acount_name}}</th>
                                        <th class="text-center" style="width:15%">{{$parent.lang.depit}}</th>
                                        <th class="text-center" style="width:15%">{{$parent.lang.credit}}</th>
                                        <th class="text-center" style="width:20%">{{$parent.lang.notes}}</th>
                                        <th class="text-center" style="width:100px">{{$parent.lang.action}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in vouchers" :key="index">
                                        <td>
                                            <b-form-input class="inborder" v-model="vouchers[index].classid" list="classes-list" />
                                            <datalist id="classes-list">
                                                <option v-for="(item,index) in classes" :key="index">{{item.classid}} - {{ item.namear }}</option>
                                            </datalist>
                                        </td>
                                        <td>
                                            {{item.acount_name}}
                                        </td>
                                        <td>
                                            <b-form-input style="width:100%" class="inborder" v-model="vouchers[index].depit" />
                                        </td>
                                        <td>
                                            <b-form-input style="width:100%" class="inborder" v-model="vouchers[index].credit" />
                                        </td>
                                        <td>
                                            <b-form-input style="width:100%" class="inborder" v-model="vouchers[index].notes" />
                                        </td>
                                        <td>
                                            <b-button variant="danger" class="btn btn-sm" style="width:50px" @click="removeme(item,index)">حذف</b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>{{depitPlus}}</th>
                                        <th>{{creditPlus}}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>
                                            <b-form-input @keypress="pressF2('classid')" v-model="newitem.classid" @change="getName()">{{newitem.classid}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-form-input v-model="newitem.acount_name" list="classes-list" @change="changeMe()"></b-form-input>
                                            <datalist id="classes-list">
                                                <option v-for="(item,index) in classes" :key="index">{{item.classid}} - {{ item.namear }}</option>
                                            </datalist>
                                        </th>
                                        <th>
                                            <b-form-input v-model="newitem.depit" @change="checkcd('d')">{{newitem.depit}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-form-input v-model="newitem.credit" @change="checkcd('c')">{{newitem.credit}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-form-input v-model="newitem.notes">{{newitem.notes}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-button variant="success" style="width:50px" class="btn btn-sm" @click="addnew()">اضافة</b-button>
                                        </th>
                                    </tr>
                                </tfoot>
                            </v-simple-table>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <label>ملاحظات</label>
                                <b-form-textarea class="inborder" v-model="notes"></b-form-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{btnname}}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
        <jv-search-class v-if="showSearch" />>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
import JvSearchClass from './jvSearchClass.vue';
export default{
    components: {JvSearchClass},
    data() {
        return {
            modal: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            pagename: this.$parent.lang.add_new_account,
            jv_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            btnname: this.$parent.lang.add,
            jvnom: 1,
            id: 0,
            vouchers: [],
            newitem: {
                id: 0,
                classid: '',
                acount_name: '',
                credit: '',
                depit: '',
                notes: '',
            },
            showSearch: false,
            classes: [],
            notes: '',
            deleted: [],
            jvtype: 1
        }
    },
    computed:{
        depitPlus: function(){
            let t = 0;
            for(let i=0;i<this.vouchers.length;i++){
                let v = this.vouchers[i].depit == '' ? 0 : this.vouchers[i].depit;
                t = parseFloat(t) + parseFloat(v);
            }
            return this.$RoundNum(t);
        },
        creditPlus: function(){
            let t = 0;
            for(let i=0;i<this.vouchers.length;i++){
                let v = this.vouchers[i].credit == '' ? 0 : this.vouchers[i].credit;
                t = parseFloat(t) + parseFloat(v);
            }
            return this.$RoundNum(t);
        },

    },
    created() {
        this.getclasses();
    },
    methods: {
        changeMe(){
            const myclasses = this.newitem.acount_name.split(" - ");
            this.newitem.classid = myclasses[0]
        },
        pressF2(field,e){
            var KeyID = (window.event) ? event.keyCode : e.keyCode;
            // // console.log(KeyID);
            if(KeyID == 43){
                if(field == 'classid'){
                    this.showSearch = true;
                }
            }
            event.preventDefault()

        },
        checkcd(id){
            if(id == 'c'){
                if(this.newitem.depit != 0){
                    this.newitem.credit = 0;
                }
            }
            if(id == 'd'){
                if(this.newitem.credit != 0){
                    this.newitem.depit = 0;
                }
            }
        },
        removeme(item,index){
            this.deleted.push(item.id)
            this.vouchers.splice(index, 1);
        },
        addnew(){
            if
            (
                this.newitem.classid == '' || 
                this.newitem.acount_name == '' || 
                (this.newitem.credit == '' && 
                this.newitem.depit == '')  || 
                (this.newitem.credit == 0 && 
                this.newitem.depit == 0) 
            ){
                return false;
            }
            this.vouchers.push(this.newitem);
            this.newitem = {
                id: 0,
                classid: '',
                acount_name: '',
                credit: '',
                depit: '',
                notes: '',
            }
        },
        getName(){
            const post = new FormData();
            post.append("type","getClassname")
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.lang.langname);
            post.append("data[classid]",this.newitem.classid);
            post.append("data[jv]",2);

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                if(res.results.data.length == 0) {
                    this.newitem.classid = '';
                    return false;
                }
                this.newitem.acount_name = res.results.data.namear;
            })
        },
        getMain(){
            //
        },
        async getJV() {
            const post = new FormData();
            post.append("type","getJV");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
            post.append("data[jvnom]",this.jvnom);
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post);
            if(response && response.data){
                
                this.id = response.data.results.data.jv.id;
                this.jv_date = response.data.results.data.jv.jv_date;
                this.notes = response.data.results.data.jv.notes;
                const rows = response.data.results.data.rows;
                let jvRow = {};
                for(let i=0;i<rows.length;i++){
                    let classname = '';
                    for(let j=0;j<this.classes.length;j++){
                        if(this.classes[j].classid == rows[i].classid){
                            // TODO : check arabic/english
                            classname =  this.classes[j].namear;
                        }
                    }
                    // console.log(rows[i])
                    jvRow = {
                        id: rows[i].id,
                        classid: rows[i].classid,
                        depit: rows[i].depit,
                        credit: rows[i].credit,
                        notes: rows[i].notes,
                        acount_name: classname
                    }
                    this.vouchers.push(jvRow)
                }
            }
        },
        getclasses() {
            const post = new FormData();
            post.append("type","getPostedCOA")
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {  
                const res = response.data;    
                this.classes = res.results.data;
            });
        },
        addit() {
            const date = new Date(this.jv_date);
            if(date.getFullYear() != localStorage.getItem('financeYear')){
                this.$snotify.error('تاريخ القيد خارج نطاق السنة المالية الحالية يرجى اختيار التاريخ المناسب', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.jvnom == 0){
                this.$snotify.error('يرجى اضافة رقم القيد يدويا', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;                
            }
            if(this.vouchers.length == 0){
                this.$snotify.error('لا يمكن اضافة قيد  فارغ', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.depitPlus != this.creditPlus && this.jvtype != 1){
                this.$snotify.error('القيد غير متوازن', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }

            const post = new FormData();
            post.append("type","addEditJV");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            // // console.log(this.$parent.lang.langname);
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname)
            post.append("data[type]",this.jvtype)
            
            for(let i=0;i<this.vouchers.length;i++){
                post.append('data[rows]['+i+'][id]', typeof this.vouchers[i].id !== 'undefined' ? this.vouchers[i].id : 0);
                post.append('data[rows]['+i+'][classid]',this.vouchers[i].classid);
                post.append('data[rows]['+i+'][acount_name]',this.vouchers[i].acount_name);
                post.append('data[rows]['+i+'][depit]',this.vouchers[i].depit);
                post.append('data[rows]['+i+'][credit]',this.vouchers[i].credit);
                post.append('data[rows]['+i+'][notes]',this.vouchers[i].notes);
            }
            post.append("data[id]",this.id);
            post.append("data[jvnom]",this.jvnom);
            post.append("data[jv_date]",this.jv_date);
            post.append("data[notes]",this.notes);
            for(let i=0;i<this.deleted.length;i++){
                post.append("data[deleted][]",this.deleted[i]);
            }
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {  
                const res = response.data;   
                document.getElementById('vohclose').click();
                let message = "تمت اضافة القيد ";
                if(this.id != 0){
                    message = "تمت تعديل القيد ";
                }else{
                    this.vouchers = [];
                    this.notes = '';
                }
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close,  
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getExpensesCall();
            })
        }
    },
}
</script>